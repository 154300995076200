import * as React from 'react'
import { Box, UnorderedList, ListItem, Heading, Text, chakra } from '@chakra-ui/react'
import { Link as LinkGatsby } from 'gatsby'

const PoliticaPrivacidad = () => {
    return (
        <Box
            maxW={{ base: 'lg', md: '3xl' }}
            mx="auto"
            px={{ base: '6', md: '8' }}
            h="full"

        >
            <Box flexDirection="column" textAlign="justify" h="full" color="gray.500">
                <Text mt="10">
                    Danios Digital informa nuestra Política de Privacidad respecto del tratamiento y protección de los datos de usuario de carácter personal que puedan ser recabados durante la navegación a través del sitio web <LinkGatsby activeStyle={{ color: "red" }} to='/'><chakra.span fontWeight="medium" color="blue.500">https://daniosdigital.com/</chakra.span></LinkGatsby>.
                </Text>

                <Text mt="3">
                    Nuestro compromiso general es recopilar únicamente los datos personales que se necesitan para prestar el servicio que ofrecemos, no vender información personal y dar a las personas la posibilidad de acceder, corregir o eliminar su información personal cuando esa sea su voluntad.
                </Text>
                <Text mt="3">
                    La recopilación de datos tiene como propósito mejorar la experiencia de usuario atendiendo a los intereses y necesidades de los visitantes. Por ello, al momento de utilizar información personal lo haremos siempre cumpliendo la normativa aplicable, y cuando sea necesario, solicitaremos tu consentimiento.
                </Text>
                <Text mt="3">
                    El uso de este sitio Web implica la aceptación de esta Política de Privacidad.
                </Text>
                <Heading fontSize="xl" textAlign="justify" my="5">Información básica sobre Protección de Datos</Heading>
                <UnorderedList>
                    <ListItem><chakra.span fontWeight="bold" >Responsable: </chakra.span>DANIOS, C.A.</ListItem>
                    <ListItem><chakra.span fontWeight="bold" >Finalidad: </chakra.span>Prestación de servicios online. Gestión de usuarios web. Comunicaciones comerciales relacionadas con nuestros servicios.</ListItem>
                    <ListItem><chakra.span fontWeight="bold" >Legitimación: </chakra.span>Consentimiento expreso e interés legítimo.</ListItem>
                    <ListItem><chakra.span fontWeight="bold" >Destinatarios: </chakra.span>No se ceden datos a terceros, salvo obligación legal o a causa de prestación de servicio por parte de un encargado de tratamiento.</ListItem>
                    <ListItem><chakra.span fontWeight="bold" >Derechos: </chakra.span>Acceder, rectificar y suprimir los datos, así como otros derechos, como se explica en la información adicional.</ListItem>
                    <ListItem><chakra.span fontWeight="bold" >Información adicional: </chakra.span>Puede consultar la información adicional y detallada sobre Protección de Datos en las cláusulas anexas que se encuentran en <LinkGatsby to="/politica-de-privacidad/"><chakra.span fontWeight="medium" color="blue.500">https://daniosdigital.com/politica-de-privacidad/</chakra.span></LinkGatsby></ListItem>
                </UnorderedList>
                <Heading fontSize="xl" textAlign="justify" my="5">Información básica sobre Protección de Datos</Heading>
                <UnorderedList>
                    <ListItem><chakra.span fontWeight="bold" >Identidad: </chakra.span>DANIOS, C.A.</ListItem>
                    <ListItem><chakra.span fontWeight="bold" >Domicilio social: </chakra.span>CTRA NACIONAL VIA LOS BARRANCOS DE FAJARDO NRO S/N ZONA POBLACION DE CHAGUARAMAS, EDO. MONAGAS, VENEZUELA.</ListItem>
                    <ListItem><chakra.span fontWeight="bold" >R.I.F. nº: </chakra.span>J-29732828-9</ListItem>
                    <ListItem><chakra.span fontWeight="bold" >Email: </chakra.span>info@daniosdigital.com</ListItem>
                </UnorderedList>
                <Text mt="3">DANIOS, C.A. ha designado un Delegado de Protección de Datos o una persona de contacto interna dentro de su organización. Si deseas hacer una consulta en relación al tratamiento de tus datos personales, puedes establecer contacto a través del correo electrónico info@daniosdigital.com.</Text>
                <Heading fontSize="xl" textAlign="justify" my="5">¿Cuáles datos personales pueden ser recabados?</Heading>
                <Text>Los datos personales que el usuario puede llegar a proporcionar:</Text>
                <UnorderedList>
                    <ListItem>Nombre y apellidos, dirección y nombre de la clínica u organización.</ListItem>
                    <ListItem>Número de teléfono y dirección de correo electrónico.</ListItem>
                    <ListItem>Ubicación.</ListItem>
                    <ListItem>Información relativa a pagos y transacciones comerciales.</ListItem>
                    <ListItem>Dirección IP, fecha y hora de acceso, identificación del navegador de internet que inicia la conexión y datos sobre el sistema operativo del dispositivo.</ListItem>
                    <ListItem>Cualquier otra información o datos que el usuario decida voluntariamente compartir con nosotros.</ListItem>
                </UnorderedList>
                <Text mt="3">En algunos casos, es obligatoria la cumplimentación del formulario de registro para acceder y disfrutar de determinados servicios ofrecidos en la web; asimismo, no facilitar los datos personales solicitados o el no aceptar la presente política de protección de datos supone la imposibilidad de suscribirse, registrarse o participar en cualquiera de las promociones en las que se soliciten datos carácter personal.</Text>
                <Heading fontSize="xl" textAlign="justify" my="5">¿Cuánto tiempo conservamos tus datos?</Heading>
                <Text mt="3">El tratamiento de los datos se mantendrá durante el tiempo necesario para cumplir con la finalidad que motivó su recopilación, por ejemplo, durante la relación comercial; así como también, para el cumplimiento de las obligaciones legales que se deriven del tratamiento de los datos.</Text>
                <Heading fontSize="xl" textAlign="justify" my="5">¿Dónde se almacenan tus datos?</Heading>
                <Text mt="3">Todas nuestras soluciones que requieran almacenamiento de datos ofrecen un nivel de protección de clase mundial suficiente para cumplir con la legislación aplicable en materia de protección de datos. Para nuestros clientes que requieran que los datos sujetos al RGPD permanezcan en la UE y no sean transferidos a los EE. UU. para su tratamiento, contamos con la tecnología que posibilita la creación de reglas y controles en el borde sobre el lugar donde se almacenan y protegen sus datos.</Text>
                <Heading fontSize="xl" textAlign="justify" my="5">Con quién compartimos tus datos</Heading>
                <Text mt="3">Danios Digital informa que sus datos personales no serán compartidos con terceros, salvo en casos de obligación legal o cuando la prestación de un servicio implique la necesidad de una relación con un encargado de tratamiento. En este último caso, los destinatarios de sus datos y bajo sus correspondientes condiciones de privacidad son:</Text>
                <UnorderedList my="2">
                    <ListItem><chakra.span fontWeight="bold" >Plataforma en la nube: </chakra.span>Cloudflare.</ListItem>
                    <ListItem><chakra.span fontWeight="bold" >Servicio de mensajería: </chakra.span>MailerLite.</ListItem>
                </UnorderedList>
                <Text>Danios Digital mantiene unos criterios estrictos de selección de encargados de tratamiento quienes se comprometen a cumplir y hacer cumplir las obligaciones establecidas en materia de protección de datos. Cabe destacar, que estos encargados del tratamiento sólo tendrán acceso a los datos estrictamente necesarios para realizar su actividad, y no podrán utilizar la información para otro fin.</Text>
                <Heading fontSize="xl" textAlign="justify" my="5">Información de contacto</Heading>
                <Text mt="3">Puede contactar con nosotros y atenderemos las dudas o inquietudes que tenga sobre el tratamiento de sus datos, lo haremos en idioma español. Puede utilizar la siguiente dirección de correo electrónico para tal fin: info@daniosdigital.com.</Text>
                <Heading fontSize="xl" textAlign="justify" my="5">Cookies</Heading>
                <Text mt="3">Este sitio web podrá hacer uso de cookies para mejorar la experiencia del usuario. La navegación por el sitio implica su aceptación, también puede enviar un mensaje en cualquier momento a info@daniosdigital.com para expresar su inconformidad.</Text>
                <Text mt="3">El usuario tiene la posibilidad de configurar el navegador de tal modo que impida la instalación de cookies en caso de que esa sea su voluntad.</Text>
                <Heading fontSize="xl" textAlign="justify" my="5">Contenido incrustado de otros sitios web</Heading>
                <Text mt="3">Este sitio web puede incluir contenido incrustado (por ejemplo, vídeos, imágenes, artículos, etc.). El contenido incrustado proveniente de otros sitios web se comporta exactamente de la misma manera que si el visitante hubiera visitado la otra web.</Text>
                <Text mt="3">Estos contenidos incrustados pueden recopilar datos sobre ti, utilizar cookies, incrustar un seguimiento adicional de terceros, y supervisar tu interacción con ese contenido incrustado, incluido el seguimiento de su interacción con el contenido.</Text>
                <Heading fontSize="xl" textAlign="justify" my="5">Analítica y publicidad</Heading>
                <Text mt="3">Este sitio web utiliza el píxel de Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, EE. UU. (“Facebook”), además de la etiqueta de análisis web Google Analytics. El proveedor es Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, EE. UU.</Text>
                <Text mt="3">Tanto el pixel de Facebook como la etiqueta de Google Analytics, son pequeños trozos de código que se instalan en las páginas web y sirven como herramientas de analítica y seguimiento para la medición de conversiones, creación de audiencias y estadísticas sobre cómo las personas usan el sitio web.</Text>
                <Text mt="3">Los datos recopilados son anónimos para el operador de este sitio web, de modo que no podemos sacar conclusiones sobre la identidad de los usuarios. Sin embargo, los datos son almacenados y procesados por estos proveedores de servicio para posteriormente facilitar el análisis de datos agregados (de todos los visitantes, no de algún usuario en particular).</Text>
                <Text mt="3">Consulte la política de privacidad de Facebook en <a href='https://www.facebook.com/about/privacy/'><chakra.span fontWeight="medium" color="blue.500">https://www.facebook.com/about/privacy/</chakra.span></a> y la de Google en <a href='https://policies.google.com/privacy'><chakra.span fontWeight="medium" color="blue.500">https://policies.google.com/privacy</chakra.span></a> para obtener más información sobre cómo proteger su privacidad.</Text>
                <Heading fontSize="xl" textAlign="justify" my="5">¿Puede modificarse la política de privacidad?</Heading>
                <Text mt="3">Dado que la protección de datos es un entorno en constante evolución, seguiremos atentos al desarrollo de la legislación aplicable y actualizaremos esta página según corresponda. Por favor, revisa esta política de privacidad con cierta periodicidad.</Text>
                <Text mt="3">Última revisión: 20/05/2022</Text>
            </Box>
        </Box >
    )
}

export default PoliticaPrivacidad